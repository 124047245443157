import React from 'react';

import { useEffect, useState } from "react";
import micButtonImage from './assets/images/mic.svg';
import recordingButtonImage from './assets/images/recording.svg';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

function Dictaphone({ note, setNote, isListening, setIsListening }) {
  // console.log("setNote", setNote);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        // console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        // console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      // console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      // console.log(transcript);
      setNote(transcript);
      mic.onerror = (event) => {
        // console.log(event.error);
      };
    };
  };

  return (
    <>
      <div className="container">
        <div className="box">
          {isListening ? <span className='pointer ml-10'
            onClick={() => setIsListening((prevState) => !prevState)}
          >
            <img src={recordingButtonImage} alt='Recording Button' />
            {/* {recordingButtonImage}  */}
            {/* 🛑 */}
          </span> : <span className='pointer ml-10'
            onClick={() => setIsListening((prevState) => !prevState)}>
            <img src={micButtonImage} alt='Mic Button' />

            {/* 🎙️ */}
          </span>}

          {/* <button onClick={() => setIsListening((prevState) => !prevState)}> */}

        </div>
      </div>
    </>
  );
}

export default Dictaphone;