// UserProfileImage.js
import React from 'react';

const UserProfileImage = ({ imageUrl, altText }) => {
    return (
        <div className="user-profile-image">
            {/* <div className="message-text">{altText}</div> */}
            <img src={imageUrl} alt={altText} className="avatar" />
        </div>
    );
};

export default UserProfileImage;
