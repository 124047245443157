import React, { useEffect, useState } from 'react';
import Chatbot from './Chatbot'; // Import your Chatbot component here
// import BotImage from './assets/images/bot.jpg';
import BotImage from './assets/images/purple-bot.png';

const RedirectButton = () => {
    const [showChatbot, setShowChatbot] = useState(false);

    const toggleChatbot = () => {
        setShowChatbot(!showChatbot);
    };

    const handleClose = () => {
        setShowChatbot(false);
    };

    // useEffect(() => {

    //     sessionStorage.removeItem('chatHistory');

    // }, [])

    // useEffect(()=>{
    //     // const clearSessionStorage = () => {
    //         // for (let i = 0; i < localStorage.length; i++) {
    //         //     const key = localStorage.key(i);
    //         //     localStorage.removeItem(key);
    //         // }
    //         sessionStorage.removeItem('chatHistory');
    //     // };
    //     // return () => {
    //         // sessionStorage.clear(); // Clear session storage on component unmount (page refresh)
    //     // clearSessionStorage();

    //     // };
    // },[])

    return (
        <div style={{ position: 'fixed', bottom: '20px', right: '10px', zIndex: '9999' }}>
            {showChatbot ? (
                <Chatbot isOpen={showChatbot} handleClose={handleClose} />
            ) : (
                <img
                    src={BotImage}
                    alt="Chat bot"
                    style={{ width: '100px', height: '100px', cursor: 'pointer', position: 'relative', bottom: '0', right: '0'}}
                    onClick={toggleChatbot}
                />
            )}
        </div>
    );
};

export default RedirectButton;

// {/* Conditionally render the Chatbot component */}
// {showChatbot && <Chatbot />}
// {/* Toggle the Chatbot component visibility on button click */}
// <img
//     src={BotImage}
//     alt="Chat bot"
//     style={{ width: '100px', height: '100px', cursor: 'pointer', position: 'relative', bottom: '0', right: '0'}}
//     onClick={toggleChatbot}
// />