import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import './assets/style/Chatbot.css';
import './assets/style/Header.css';
import userAvatarUrl from './assets/images/user.jpeg';
import chatbotAvatarUrl from './assets/images/logo-new.png';
import UserProfileImage from './UserProfileImage';
import ReactHtmlParser from 'react-html-parser';
import closeButtonImage from './assets/images/cancel.png';
import sendButtonImage from './assets/images/send.svg';
import resizeButtonImage from './assets/images/resize.svg';
import recordingButtonImage from './assets/images/recording.svg';
import logoImage from './assets/images/logo.png';
import Dictaphone from './VoiceRecord';

const Chatbot = ({ handleClose }) => {

    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    // const [chatHistory, setChatHistory] = useState([]);
    const chatAreaRef = useRef(null);
    const textareaRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // State to manage loader display
    const [botTypingMessage, setBotTypingMessage] = useState(null);
    const [resizing, setResizing] = useState(false);
    const [initialMouseX, setInitialMouseX] = useState(null);
    const [initialMouseY, setInitialMouseY] = useState(null);
    const [initialWidth, setInitialWidth] = useState(null);
    const [initialHeight, setInitialHeight] = useState(null);
    const [minWidth, setMinWidth] = useState(400); // Minimum width of the chatbot container
    const [minHeight, setMinHeight] = useState(400); // Minimum height of the chatbot container
    const [tablesVisibility, setTablesVisibility] = useState(false);
    const [imageVisible, setImageVisible] = useState(false);
    const [note, setNote] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Save the chat history
    // useEffect(() => {
    //     if (messages.length > 0) {

    //         const chatHistory = {
    //             userMessages: messages.filter(message => message.user),
    //             botMessages: messages.filter(message => !message.user)
    //         };
    //         sessionStorage.setItem('chatHistory', JSON.stringify(messages)); // Save chat history to local storage
    //     } else {
    //         let getData = sessionStorage.getItem('chatHistory');
    //         if (getData) {
    //             getData = JSON.parse(getData);
    //             setMessages(getData);
    //         }
    //     }
    // }, [messages]);

    // for speech to text
    useEffect(() => {
        setInput(note);
    }, [note]);

    useEffect(() => {
        const handleResize = () => { 
            setWindowWidth(window.innerWidth); 
        };
        window.addEventListener('resize', handleResize);     // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.resizeTo(windowWidth, window.innerHeight);
        if (windowWidth < 1024) {
        chatContainerRef.current.style.width = `${windowWidth-50}px`;
            setMinWidth(windowWidth - 50);
        }
    }, [windowWidth]);

    useEffect(() => {
        if (isLoading) {
            setBotTypingMessage({
                type: "bot",
                message: "",
            });
        } else {
            setBotTypingMessage(null);
        }
    }, [isLoading]);

    const speak = (textInput) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(textInput);
            window.speechSynthesis.speak(utterance);
        } else {
            alert('Speech synthesis is not supported in this browser.');
        }
    };

    // Integrtaed api and 2 second delay
    const chatWithGPT3 = async (userInput) => {
        setIsLoading(true); // Set loading to true when fetching bot response
        const apiEndpoint = 'https://chatbot-ai.kokocmms.biz/webhook';
        const data = {
            message: userInput,
        };

        try {
            const response = await axios.post(apiEndpoint, data);
            const botResponse = response.data.bot_response;
            // Simulate a minimum loading time of 2 seconds
            await new Promise(resolve => setTimeout(resolve, 2000));
            const newAiMessage = { text: botResponse, user: false };
            setMessages((prevMessages) => [...prevMessages, newAiMessage]);

            // Speak the bot's response
            speak(botResponse);
        } catch (error) {
            console.error('Error communicating with the API:', error.message);
        } finally {
            setIsLoading(false); // Hide loader when response is received
        }
    };

    // Submit event
    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (!input.trim()) return;
        const userMessage = { text: input, user: true };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        document.querySelector('.chatbot-input-form textarea').value = '';
        setInput('');
        setIsListening(false)
        await chatWithGPT3(input);
    };

    useEffect(() => {
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        // Scroll the chat area to the leftmost position
        chatAreaRef.current.scrollLeft = 0;
    }, [messages, botTypingMessage]);

    const isHtmlResponse = (text) => {
        const doc = new DOMParser().parseFromString(text, 'text/html');
        return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
    };

    const handleMouseDown = (e) => {
        setResizing(true);
        setInitialMouseX(e.clientX);
        setInitialMouseY(e.clientY);
        setInitialWidth(chatContainerRef.current.offsetWidth);
        setInitialHeight(chatContainerRef.current.offsetHeight);
    };

    const handleMouseMove = (e) => {
        if (resizing) {
            const deltaX = e.clientX - initialMouseX;
            const deltaY = e.clientY - initialMouseY;

            const maxWidth = window.innerWidth - 70;
            const maxHeight = window.innerHeight - 70;

            // const newWidth = Math.max(initialWidth - deltaX, minWidth);
            // const newHeight = Math.max(initialHeight - deltaY, minHeight);

            const newWidth = Math.min(Math.max(initialWidth - deltaX, minWidth), maxWidth);
            const newHeight = Math.min(Math.max(initialHeight - deltaY, minHeight), maxHeight);

            chatContainerRef.current.style.width = `${newWidth}px`;
            chatContainerRef.current.style.height = `${newHeight}px`;
        }
    };
    const handleMouseUp = () => {
        setResizing(false);
    };


    useEffect(() => {
        document.body.addEventListener('mousemove', handleMouseMove);
        document.body.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.body.removeEventListener('mousemove', handleMouseMove);
            document.body.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resizing]);

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMoveOnDocument = (event) => {
            if (resizing) {
                const deltaX = event.clientX - initialMouseX;
                const deltaY = event.clientY - initialMouseY;
                const maxWidth = window.innerWidth - 70;
                const maxHeight = window.innerHeight - 70;
                const newWidth = Math.min(Math.max(initialWidth - deltaX, minWidth), maxWidth);
                const newHeight = Math.min(Math.max(initialHeight - deltaY, minHeight), maxHeight);
                chatContainerRef.current.style.width = `${newWidth}px`;
                chatContainerRef.current.style.height = `${newHeight}px`;
            }
            setMousePosition({ x: event.clientX, y: event.clientY });
        };
        document.addEventListener('mousemove', handleMouseMoveOnDocument);
        return () => {
            document.removeEventListener('mousemove', handleMouseMoveOnDocument);
        };
    }, [resizing])

    // For table scroll
    const getHtmlTableClass = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const table = doc.querySelectorAll('table tr');
        if (table) {
            const rowCount = table.length;
            // console.log("rowCount", rowCount)
            if (rowCount > 10) {
                return 'scrollable-table';
            }
        }
        return '';
    };

    const toggleTableVisibility = () => {
        setTablesVisibility(!tablesVisibility);
    };

    const toggleImageVisibility = () => {
        setImageVisible(!imageVisible);
    };

    // Function to render bot response including images
    const renderBotResponse = (message) => {
        let images = [];
        let tables = [];
        let messageContent = message;

        if (message.includes('<img')) {
            const doc = new DOMParser().parseFromString(message, 'text/html');
            const imgNodes = doc.querySelectorAll('img');
            imgNodes.forEach((imgNode, index) => {
                images.push(<img key={index} src={imgNode.src} alt="Bot Image New" />);
            });
            messageContent = message.replace(/<img[^>]*>/g, '');
        }

        if (message.includes('<table')) {
            const doc = new DOMParser().parseFromString(message, 'text/html');
            const tableNodes = doc.querySelectorAll('table');

            const tableElements = Array.from(tableNodes).map(tableNode => tableNode.outerHTML);
            // console.log("tableElementstableElements", tableElements)
            tables.push(tableElements)

            messageContent = message.replace(/<table[^>]*>.*<\/table>/g, '');
        }

        return (
            <div>
                {/* <div className={`message-html ${getHtmlTableClass(messageContent)}`}> */}
                {isHtmlResponse(messageContent) ? (
                    <div className={`message-html`}>
                        {ReactHtmlParser(messageContent)}
                    </div>
                ) : (
                    <div className="message-text">{messageContent}</div>
                )}
                {images.length > 0 && (
                    <div className="toggle-info">
                        <button onClick={() => toggleImageVisibility()} style={{ display: images.length > 0 ? 'block' : 'none' }}>Graph</button>
                        <div className="image-container" style={{ display: imageVisible ? 'block' : 'none' }}>
                            {images}
                        </div>
                    </div>
                )}

                {tables.length > 0 && (
                    <div className="toggle-info">
                        <button onClick={toggleTableVisibility} tyle={{ display: tables.length > 0 ? 'block' : 'none' }}>Table</button>
                        {/* {console.log("tablesVisibility--", tablesVisibility)} */}
                        <div className="dynamic-table scrollable-table" style={{ display: tablesVisibility ? 'block' : 'none' }}>
                            {ReactHtmlParser(tables?.[0])}
                        </div>
                    </div>
                )}

                {/* {tables} */}
            </div>
        );
    };

    const customMicStyles = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5px',
        marginRight: '9px'
    };

    const handleChange = (e) => {
        setInput(e.target.value);
        const cursorPosition = e.target.selectionStart;
        e.target.setSelectionRange(cursorPosition, cursorPosition);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.value.trim() !== '') {
            handleSubmit()
        }
    };

    return (
        <div>
            <div ref={chatContainerRef} className="chatbot-container" style={{ width: minWidth, height: minHeight }}>
                <div className="header">
                    <div className="header-title">
                        koko Chatbot
                    </div>
                    <button className="close-button" onClick={handleClose}>
                        <img src={closeButtonImage} alt="Close" />

                    </button>
                </div>
                <div className="chatbot-messages" ref={chatAreaRef} style={{ height: chatContainerRef?.current?.style?.height || minHeight }}>
                    {/* for messages */}
                    {messages.map((message, index) => (
                        <div key={index} className={`message ${message.user ? 'user-message' : 'ai-message'}`}>
                            {message.user ? (
                                <div className="user-message-container">
                                    <div className="message-text">{message.text}</div>
                                    <UserProfileImage imageUrl={userAvatarUrl} altText="User Avatar" />
                                </div>
                            ) : (
                                <div className="ai-message-container">
                                    <UserProfileImage imageUrl={logoImage} altText="Chatbot Avatar" />
                                    {renderBotResponse(message.text)}
                                </div>

                            )}
                        </div>
                    ))}
                    {/* for loader */}
                    {botTypingMessage && (
                        <div key="typing" className="message ai-message ">
                            <div className="typing-animation">{botTypingMessage.message}</div>

                        </div>
                    )}
                </div>
                <form className="chatbot-input-form" onSubmit={handleSubmit}>
                    {/* <input
                        type="text"
                        value={`${input}`}
                        onChange={(e) => {
                            setInput(e.target.value)
                        }}
                        placeholder="Type your message..."
                        disabled={isLoading}
                    /> */}
                    <textarea
                        className='text-area'
                        // style={{ resize: "none", width: '100%' }}
                        value={input}
                        ref={(input) => {input && input.focus() }} 
                        onChange={handleChange}
                        placeholder="Type your message..."
                        onKeyDown={handleKeyPress}
                        disabled={isLoading}
                    // row={2}
                    />
                    {/* Replace input field with Dictaphone component */}
                    <Dictaphone note={note} setNote={setNote} isListening={isListening} setIsListening={setIsListening} />

                    <button className='send-button' type="submit">
                        <img src={sendButtonImage} alt='Send Button' />
                    </button>
                </form>

                {windowWidth > 1024 && 
                <div
                    className="resizer"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    <img src={resizeButtonImage} alt='Drag' />
                </div>
            }
            </div>
        </div>
    );
};
export default Chatbot;
