import './App.css';
import React from 'react';
import Chatbot from './components/Chatbot';
import RedirectButton from './components/RedirectButton';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import kokoLogoImage from './components/assets/images/koko-logo.png';

function App() {
  return (
    <div className="App">
      {/* <h1>AI Chatbot</h1> */}
      <img src={kokoLogoImage} style={{height: '150px'}} alt="Kokoro Logo" id='logo' />
      <Router>
        <Routes>
          <Route path="/" element={<RedirectButton />} />
          {/* <Route path="/chatbot" element={<Chatbot />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
